const commonMessages = {
    en: {
        'name': 'name',
        'name2': 'Name',
        'code': 'code',
        'type_here': 'Type here...',
        'sign_in': 'Sign In',
        'are_you_sure': 'Are you sure?',
        'delete_it': 'Yes, delete it!',
        'move_to_trash': 'Move to trash!',
        'moved_to_trash': 'Moved to trash',
        'no_cancel': 'No, cancel!',
        'deleted': 'Deleted',
        'cancelled': 'Cancelled',
        'restore': 'Restore',
        'restored': 'Restored',
        'upload_image': 'Click here to upload image, or drag here..',
        'save': 'Save',
        'cancel': 'Cancel',
        'success_message': 'Your work has been saved',
        'error_message': 'There were some errors during submission',
        'error': 'Error',
        'title': 'Title',
        'The value is required':'The value is required',
        'search':'Search',
        'prev':'Previous',
        'next':'Next',
        'options':'Options',
        'body': 'Content',
        'description':'Description',
        'post_categories': 'Post categories',
        'active':'Active',
        'inactive':'Inactive',
        'basic_media':'Basic Media',
        'media_files':'Media files',
        'without_name':'Without name',
        'short_description':'Short description',
        'specifications':'Specifications',
        'questions_and_answers':'Q&A',
        'sizing_guide':'Sizing guide',
        'manage_stock':'Manage stock',
        'initial_stock' : 'Iniatial stock',
        'stock_status':'Stock status',
        'inStock':'In stock',
        'outOfStock':'Out of stock',
        'onBackOrder':'In stock at the supplier',
        'managing':'Managing',
        'notManaging':'Not managing',
        'needsTitle': 'It must have a title!',
        'needsPrice': 'It must have a price!',
        'free_delivery':'Free delivery',
        'password':'Password',
        'back': 'Back',
        'comment':'Comment',
        'published_date': 'Published at',
        'lang_title_error':'Title is required when other language inputs are filled in that language',
        'lang_name_error':'Name is required when other language inputs are filled in that language',
        "no_result": "No result",
        "locale": "Available languages",
        "link": "Link",
        "all_icons": "All icon",
        "font-awesome-regular": "Font awesome regular",
        "font-awesome-solid": "Font awesome solid",
        "font-awesome-brands": "Font awesome brands",
        "search_all_icon": "Search all icons...",
        "insert": "Insert",
        "how_to": "Please click on the icon to choose another one for saving!",
    },
    hu: {
        'name': 'név',
        'name2': 'Név',
        'code': 'kód',
        'type_here': 'Keresés',
        'sign_in': 'Bejelentkezés',
        'are_you_sure': 'Biztos vagy benne?',
        'delete_it': 'Igen, töröld!',
        'move_to_trash': 'Kukába helyezés!',
        'moved_to_trash': 'Áthelyezve a kukába',
        'no_cancel': 'Mégse!',
        'deleted': 'Törölve',
        'cancelled': 'Kérés törölve',
        'restore': 'Visszaállítás',
        'restored': 'Visszaállítva',
        'upload_image': 'Kattints a képfeltöltéshez vagy húzd ide...',
        'save': 'Mentés',
        'cancel': 'Mégsem',
        'success_message': 'Sikeresen elmentve',
        'error_message': 'Mentés sikertelen',
        'error': 'Hiba',
        'title': 'Cím',
        'The value is required':'Kötelező mező',
        'search':'Keresés',
        'prev':'Előző',
        'next':'Következő',
        'options':'Opciók',
        'body': 'Tartalom',
        'description':'leírás',
        'post_categories': 'Poszt kategoriák',
        'active':'Aktív',
        'inactive':'Inaktív',
        'basic_media':'Főkép',
        'media_files':'További képek',
        'without_name':'Név nélkül',
        'short_description':'Rövid leírás',
        'specifications':'Specifikációk',
        'questions_and_answers':'Kérdések és válaszok',
        'sizing_guide':'Méret leírás',
        'manage_stock':'Stock menedzselés',
        'initial_stock' : 'Kezdő készlet',
        'stock_status':'Stock státus',
        'inStock':'Készleten',
        'outOfStock':'Kifogyott',
        'onBackOrder':'Készleten a beszállítónál',
        'managing':'Kezelés',
        'notManaging':'Nincs kezelve',
        'needsTitle': 'Nem lehet üres a név mező!',
        'needsPrice': 'Nem lehet üres az ár mező!',
        'free_delivery':'Ingyenes szállítás',
        'password':'Jelszó',
        'back': 'Vissza',
        'comment':'Megjegyzés',
        'published_date': 'Közzétéve',
        'lang_title_error':'A cím megadása kötelező, ha az adott nyelven töltött ki már más adatot',
        'lang_name_error':'A név megadása kötelező, ha az adott nyelven töltött ki már más adatot',
        "no_result": "Még nincs felvezetve vagy nem található a keresett elem",
        "link": "Link",
        "link_name": "Link név",
        "links": "Link",
        "all_icons": "Összes ikon",
        "font-awesome-regular": "Szabályos ikonok",
        "font-awesome-solid": "Solid ikonok",
        "font-awesome-brands": "Márka ikonok",
        "search_all_icon": "Keresés minden ikon között...",
        "insert": "Alkalmaz",
        "how_to": "Kérjük, kattintson az ikonra, hogy újat válasszon ki a mentéshez!",
        "basic_ro": "Banner kép (RO)",
        "basic_hu": "Banner kép (HU)",
        "basic_en": "Banner kép (EN)",

    },
    ro: {
        'name': 'nume',
        'name2': 'Nume',
        'code': 'cod',
        'type_here': 'Căutare',
        'sign_in': 'Autentificare',
        'are_you_sure': 'Ești sigur??',
        'delete_it': 'Da, șterge-l!',
        'move_to_trash': 'Aruncă la gunoi!',
        'moved_to_trash': 'Mutat la gunoi',
        'no_cancel': 'Nu, anulează!',
        'deleted': 'Șters',
        'cancelled': 'Anulat',
        'restore': 'Restabili',
        'restored': 'Restaurat',
        'upload_image': 'Faceți clic aici pentru a încărca imaginea sau trageți aici..',
        'save': 'Salvați',
        'cancel': 'Anulează',
        'success_message': 'Salvat cu succes',
        'error_message': 'Au apărut câteva erori',
        'error': 'Eroare',
        'title': 'Titlu',
        'The value is required':'Câmp obligatoriu',
        'search':'Căutare',
        'prev':'Anterioară',
        'next':'Următoare',
        'options':'Opțiuni',
        'body': 'Conținut',
        'description':'descriere',
        'post_categories': 'Categoriile de post',
        'active':'Activ',
        'inactive':'Inactive',
        'basic_media':'Imaginea principală',
        'media_files':'Fișiere media',
        'without_name':'Fără nume',
        'short_description':'Scurta descriere',
        'specifications':'Specificații',
        'questions_and_answers':'Întrebări și răspunsuri',
        'sizing_guide':'Ghid de marimi',
        'manage_stock':'Manage stock',
        'stock_status':'Stock status',
        'inStock':'În stoc',
        'outOfStock':'Epuizat',
        'onBackOrder':'În stoc la furnizor',
        'managing':'Administrare',
        'notManaging':'Not managing',
        'needsTitle': 'Trebuie să aibă un titlu!',
        'needsPrice': 'Trebuie să aibă un preț!',
        'free_delivery':'Livrarea gratuită',
        'password':'Parola',
        'back': 'Înapoi',
        'published_date': 'Publicat la',
        'lang_title_error':'Titlul este necesar atunci când alte intrări de limbă sunt completate în limba respectivă',
        'lang_name_error':'Numele este necesar atunci când alte intrări de limbă sunt completate în limba respectivă',
        "no_result": "Nu a fost găsită nicio căutare",
        "link_name": "Link nume",
        "link": "Link",
        "links": "Link",
        "all_icons": "Toate icon",
        "font-awesome-regular": "Regular icon",
        "font-awesome-solid": "Solid icon",
        "font-awesome-brands": "Brands icon",
        "search_all_icon": "Căutați toate pictogramele...",
        "insert": "Aplicați",
        "how_to": "Vă rugăm să dați clic pe pictograma pentru a selecta altul pentru salvare!",
        "image_ro": "Banner poză (RO)",
        "image_hu": "Banner poză (HU)",
        "image_en": "Banner poză (EN)",
    }
}

export default commonMessages