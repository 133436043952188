const menuMessages = {
    en: {
        'Posts':'Posts',
        'Orders':'Orders',
        'Post Categories':'Post Categories',
        'Labels':'Labels',
        'Manufacturer':'Manufacturer',
        'Product Categories':'Product Categories',
        'Products':'Products',
        'Stock Manager':'Stock Manager',
        'Discounts':'Discounts',
        'Coupons':'Coupons',
        'Delivery Prices':'Delivery Prices',
        'Order List':'Order List',
        'Post List':'Post List',
        'Create Post':'Create Post',
        'Trash':'Trash',
        'Post Category List':'Post Category List',
        'Create Post Category':'Create Post Category',
        'Label List':'Label List',
        'Create Label':'Create Label',
        'Manufacturer List':'Manufacturer List',
        'Edit Manufacturer':'Edit Manufacturer',
        'Create Manufacturer':'Create Manufacturer',
        'Product Category List':'Product Category List',
        'Create Product Category':'Create Product Category',
        'Product List':'Product List',
        'Create Product':'Create Product',
        'Stock List':'Stock List',
        'Discount List':'Discount List',
        'Create Discount':'Create Discount',
        'Coupon List':'Coupon List',
        'Create Coupon':'Create Coupon',
        'Delivery Price List':'Delivery Price List',
        'Create Delivery Price':'Create Delivery Price',
        'Order Detail':'Order Detail',
        'Post Trash':'Post Trash',
        'Post Category Trash':'Post Category Trash',
        'Label Trash':'Label Trash',
        'Manufacturer Trash':'Manufacturer Trash',
        'Product Category Trash':'Product Category Trash',
        'Product Trash':'Product Trash',
        'Coupon Trash':'Coupon Trash',
        'Edit Post':'Edit Post',
        'Edit Post Category':'Edit Post Category',
        'Edit Label':'Edit Label',
        'Edit Product Category':'Edit Product Category',
        'Edit Discount':'Edit Discount',
        'Edit Coupon':'Edit Coupon',
        'Edit Delivery Price':'Edit Delivery Price',
        'Popups':'Popup notification',
        'Popup List':'Popup notification list',
        'Create Popup':'Create popup notification',
        'Popup Trash':'Popup Trash',
        'Banners':'Banner',
        'Banner List':'Banner list',
        'Create Banner':'Create Banner',
        'Banner Trash':'Banner Trash',

    },
    hu: {
        'Posts':'Cikkek',
        'Orders':'Rendelések',
        'Post Categories':'Cikk kategóriák',
        'Labels':'Címkék',
        'Manufacturer':'Termelők',
        'Product Categories':'Termékkategóriák',
        'Products':'Termékek',
        'Stock Manager':'Készlet kezelés',
        'Discounts':'Kedvezmények',
        'Coupons':'Kuponok',
        'Delivery Prices':'Szállítási díjak',
        'Order List':'Rendelések listája',
        'Post List':'Cikkek listája',
        'Create Post':'Cikk létrehozása',
        'Trash':'Kuka',
        'Post Category List':'Cikk kategóriák listája',
        'Create Post Category':'Cikk kategória létrehozása',
        'Label List':'Címkék listája',
        'Create Label':'Címke létrehozása',
        'Manufacturer List':'Termelők listája',
        'Edit Manufacturer':'Termelő szerkesztése',
        'Create Manufacturer':'Termelő létrehozása',
        'Product Category List':'Termékkategóriák listája',
        'Create Product Category':'Termékkategória létrehozása',
        'Product List':'Termékek listája',
        'Create Product':'Termék létrehozása',
        'Edit Product':'Termék szerkesztése',
        'Stock List':'Készlet lista',
        'Discount List':'Kedvezmények listája',
        'Create Discount':'Kedvezmény létrehozása',
        'Coupon List':'Kuponok listája',
        'Create Coupon':'Kupon létrehozása',
        'Delivery Price List':'Szállítási díjak listája',
        'Create Delivery Price':'Szállítási díj létrehozása',
        'Order Detail':'Rendelés részletei',
        'Post Trash':'Cikkek archívuma',
        'Post Category Trash':'Cikk kategóriák archívuma',
        'Label Trash':'Címkék archívuma',
        'Manufacturer Trash':'Termelők archívuma',
        'Product Category Trash':'Termékkategóriák archívuma',
        'Product Trash':'Termékek archívuma',
        'Coupon Trash':'Kuponok archívuma',
        'Edit Post':'Cikk szerkesztése',
        'Edit Post Category':'Cikk kategória szerkesztése',
        'Edit Label':'Címke szerkesztése',
        'Edit Product Category':'Termékkategória szerkesztése',
        'Edit Discount':'Kedvezmény szerkesztése',
        'Edit Coupon':'Kupon szerkesztése',
        'Edit Delivery Price':'Szállítási díj szerkesztése',
        'Popups':'Felugró értesítés',
        'Popup List':'Felugró értesítés lista',
        'Create Popup':'Felugró értesítés létrehozása',
        'Edit Popup':'Felugró értesítés szerkesztése',
        'Popup Trash':'Felugró értesítések archívuma',
        'Banners':'Banner ',
        'Banner List':'Banner lista',
        'Create Banner':'Banner létrehozása',
        'Edit Banner':'Banner szerkesztése',
        'Banner Trash':'Banner archívuma',
    },
    ro: {}
}

export default menuMessages